import { render, staticRenderFns } from "./DeliveryMethods.vue?vue&type=template&id=27939046&scoped=true&"
import script from "./DeliveryMethods.vue?vue&type=script&lang=js&"
export * from "./DeliveryMethods.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryMethods.vue?vue&type=style&index=0&id=27939046&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27939046",
  null
  
)

export default component.exports